import React from 'react'

const HeaderTeam = (props) => (
    <header id="header">
        <h1>Our Team</h1>
        <p>Get to Know the C2STEM Researchers</p>
    </header>
)

export default HeaderTeam
