import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderTeam from '../components/HeaderTeam'
import biswas from '../assets/images/biswas.jpg'
import ledeczi from '../assets/images/ledeczi.jpg'
import shuchi from '../assets/images/shuchi.jpg'
import kevin from '../assets/images/kevin.jpg'
import schwartz from '../assets/images/schwartz.jpg'
import LDC from '../assets/images/LDC.jpg'
import satabdi from '../assets/images/satabdi.jpg'
import chin from '../assets/images/chin.jpg'
import c2stemwhite from '../assets/images/c2stem-white.png'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="C2STEM Team" />
        <HeaderTeam />
          <div id="main">
          <section id="content" className="main">
              <div className="inner">
                  <header className="major">
                      <h1>Research Team</h1>
                  </header>
                  <h2 id="elements">Principal Investigators</h2>
                  <div className="row 200%">
                      <div className="6u 12u(medium)">
                          <p><span className="image left"><img src={biswas} alt="" /></span><strong>Gautam Biswas, Vanderbilt University:</strong> Gautam Biswas is a Cornelius Vanderbilt Professor of Engineering, Professor of Computer Science and Computer Engineering in the EECS Department, and a Senior Research Scientist at the Institute for Software Integrated Systems (ISIS) at Vanderbilt University.  Currently, Prof. Biswas is the lead on the VISOR (Vanderbilt Initiative for Smart cities Operations and Research) TIPS center at Vanderbilt University.<br />
                              Prof. Biswas conducts research in Intelligent Systems, and has been developing modeling  and simulation-based environments for STEM learning and instruction. The most notable project in this area is the Teachable Agents project, where students learn science by building causal models of natural processes. More recently, he has exploited the synergy between computational thinking ideas and STEM concepts and practices that help students learn STEM topics by building simulation models. He has also developed innovative educational data mining techniques for studying students’ learning behaviors and linking them to metacognitive strategies. In all of his projects, there has been a strong emphasis on scaffolding students’ learning of metacognitive strategies, and preparing them for future learning.<br />
                              In other research, he works on diagnosis, prognosis, and fault-adaptive control of complex, Cyber Physical Systems. He has been working on machine learning methods for diagnosis, and developing methods that combine model-based and data-driven approaches for diagnostic and prognostic reasoning. For this work, along with researcher at Honeywell, he received the NASA 2011 Aeronautics Research Mission Directorate Technology and Innovation Group Award for Vehicle Level Reasoning System and Data Mining methods to improve aircraft diagnostic and prognostic systems. Recently, he has also been extending this work to Smart CIty applications, in particular to support incident analyses and Smart Buildings applications.<br />
                              His research has been supported by funding from ARL, NASA, NSF, DARPA, and the US Department of Education.He has published extensively, and has over 600 refereed publications. He is an associate editor of the Prognostics and Health Management Journal, Metacognition and Learning, and the IEEE Transactions on Learning Technologies. He is a Fellow of the IEEE and the  Prognostics and Health Management (PHM) Society.
                          </p>
                          <p><span className="image right"><img src={ledeczi} alt="" /></span><strong>Akos Ledeczi, Vanderbilt University:</strong> Akos Ledeczi's two main research areaa are computer science education and wireless sensor networks (WSN). NetsBlox, his open source, web-based visual programming environment for K12 supports synergistic learning of STEM and Computational Thinking. He co-authored a highly popular introductory programming MOOC available on Coursera. In the WSN domain, his team has developed a novel WSN-based countersniper system. An enhanced version is soldier wearable and not only does it localize the shooter very accurately, but it can also tell the caliber and the weapon type. The latest version is smartphone-based. Their radio interferometric positioning approach has gained much attention and resulted in the Best Paper Award at the ACM Sensys 2007 conference.<br />

                              Prof. Ledeczi's other focus is Model Integrated Computing. His lab's latest open source software tool, the Web-based Generic Modeling Environment is a web- and cloud-based, collaborative, configurable toolkit for creating domain-specific visual modeling and program synthesis environments. </p>
                          <p><span className="image left"><img src={shuchi} alt="" /></span><strong>Shuchi Grover, Independent Researcher:</strong> A computer scientist and learning scientist by training, Dr. Shuchi Grover’s work in computer science (CS) and STEM education since 2000 has spanned both formal and informal settings in the US, Europe, and Asia. Her current research centers on computational thinking (CT), CS education, and STEM+CT integration mainly in formal K-12 settings. Formerly a senior research scientist at SRI International, Dr. Grover is a recipient of several grants from the National Science Foundation to conduct research on curriculum and assessments in STEM learning and CT in varied PK-12 contexts. She also works at the intersection of learning, assessment, and big data analytics to shape future environments for deeper learning. She has authored close to 100 well-cited scholarly and mainstream articles. She is a member of the ACM Education Council and the Computer Science Teachers Association’s task force on Computational Thinking, advisor to the K-12 CS Framework and to K-12 school districts on CS implementation/integration, and on the editorial board of ACM Transactions on Computing Education. She earned a Ph.D. in Learning Sciences & Technology Design from Stanford University (with a focus on Computer Science Education), Masters degrees in Education (Harvard University) and Computer Science (Case Western Reserve University), and Bachelors degrees in Computer Science and Physics from BITS Pilani (India).</p>
                      </div>
                      <div className="6u 12u(medium)">
                          <p><span className="image left"><img src={kevin} alt="" /></span><strong>Kevin McElhaney, SRI International:</strong> Kevin McElhaney is Senior Researcher of science and engineering education in SRI’s Center for Technology in Learning. He conducts design, implementation, and evaluation research on curricula, assessments, and teacher professional development in K-12 across the science, engineering, and computer science disciplines. Of particular interest are the alignment of technology-enhanced curriculum materials, instructionally supportive assessments, and professional development opportunities with national frameworks and standards, such as the Framework for K-12 Science Education, the K-12 Computer Science Framework, and the Next Generation Science Standards.<br />

                              Dr. McElhaney’s current work integrates the disciplines of science, engineering, and computer science by anchoring authentic engineering design problems and computational thinking tasks to project-based investigations of science phenomena, problems, and issues. This approach lowers barriers to implementing engineering and computer science in public school contexts and helps make science instruction relevant, meaningful, and compelling to diverse students. Dr. McElhaney takes advantage of principled design approaches that align curriculum and assessment to frameworks and standards and that integrate content and practice.<br />

                              Prior to joining SRI, McElhaney was a postdoctoral scholar and graduate researcher at University of California, Berkeley. At UC Berkeley he was a fellow of the NSF-funded Technology Enhanced Learning in Science (TELS) Center and worked on its related NSF projects Visualizing to Integrate Science Understanding for All Learners (VISUAL) and Continuous Learning and Automated Scoring in Science (CLASS). He also taught high school mathematics and science for five years in California and Missouri and conducted materials research in the Microsystems Technology department at Intel Corporation.</p>
                          <p><span className="image right"><img src={schwartz} alt="" /></span><strong>Dan Schwartz, Stanford University:</strong> Professor Daniel L. Schwartz is the director of the AAALab. Dr. Schwartz taught middle-school for many years, before undertaking a PhD in Human Learning and Cognition at Columbia University. His niche is the ability to bridge basic research on human cognition with creative designs and experiments to improve STEM learning, often using computer technology.</p>
                          <p><span className="image right"><img src={LDC} alt="" /></span><strong>Luke Conlin, Salem State University:</strong>Luke Conlin is an Assistant Professor in the Department of Chemistry and Physics at Salem State University.  Conlin’s research pushes beyond purely cognitive models of learning to explore how students’ conceptual understanding of physics intersects with their epistemology (i.e., stances toward knowledge and learning) and affect (e.g., emotions and feelings).  Conducting interaction analysis of video records of students’ collective behavior, Conlin explores the conditions under which students make sense of physics together.  Findings from his research highlight how to recognize and build upon students’ strengths to inform instructional practice.  His current interests include studying the processes by which students develop and assess computationally models of physical systems.<br />

                              Conlin earned a Ph.D. in Science Education from the University of Maryland, College Park, with a focus on physics education research, as well as a B.S. in Astrophysics from Tufts University.  As current chair of the Physics Education Research Leadership and Organizing Council, Conlin assesses the needs of the Physics Education Research community and designs policy to meet those needs. Conlin also engages in astronomy outreach by regularly conducting public observations at Salem State University’s Collins Observatory and developing innovative learning materials for emerging bilingual students.
                          </p>
                      </div>

                  </div>
                  <hr className="major" />

                  <h2 id="elements">Researchers</h2>
                  <div className="row 200%">
                      <div className="6u 12u(medium)">
                          <p><span className="image left"><img src={satabdi} alt="" /></span><strong>Satabdi Basu, SRI International:</strong> Satabdi Basu, Ph. D., is a CS Education researcher at SRI International’s Center for Education Research and Innovation. Her research in Computer Science Education spans designing curricula, tools, and learning environments that help in developing computational competencies, and developing assessments to evaluate such competencies. Her interests also encompass exploring the synergies between CS and STEM, and the use of modeling and simulations for synergistic learning of science and Computational Thinking in K-12. She has worked on developing adaptive scaffolds for open-ended modeling and simulation based environments, and is interested in applying different forms of learning analytics to better understand, assess, and scaffold students' learning behaviors in such technology-rich open-ended environments.<br />
                              Basu received her undergraduate degree in Computer Science and Engineering from the West Bengal University of Technology, India, and her M.S. and Ph.D. degrees in Computer Science from Vanderbilt University, Nashville, TN, USA.
                          </p>
                          <p><span className="image left"><img src={chin} alt="" /></span><strong>Doris Chin, Stanford University:</strong> Doris B. Chin is a Senior Research Scholar with the Stanford Graduate School of Education. Her research focuses on instructional methods and technologies to support learning across formal and informal environments, particularly in STEM content areas.  A recurring theme across several current projects is the development of choice-based assessments to measure students’ learning processes.  She has extensive experience in experimental design, data analysis, project management, as well as classroom research and curriculum development.
                              Before joining Stanford in 2006, she spent several years on the frontlines of informal learning as an educator at The Tech Museum of Innovation in San Jose, California. As a programs specialist, she was responsible for creating hands-on activities for visitors, developing and teaching lab classes for school field trips, as well as training for staff and volunteers.  She also worked in the exhibits department as a content developer, focusing on science and design thinking.
                              Doris has an M.S. in Statistics and a Ph.D. in Genetics from the University of California, Davis.  Her dissertation was on the evolution of disease resistance in plants, so she likes to think that she has done her part in ensuring that people eat their veggies.</p>
                          {/*<p><span className="image left"><img src={pic10} alt="" /></span><strong>Miklos Maroti, Vanderbilt University:</strong> Lorem ipsum dolor sit accumsan interdum nisi, quis tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget tempus vestibulum ante ipsum primis in faucibus magna blandit adipiscing eu felis iaculis.</p>*/}
                          <p><span className="image right"><img src={c2stemwhite} alt="" /></span><strong>Rachel Wolf, Stanford University:</strong>Rachel Wolf is a research scholar with the Stanford Graduate School of Education. Her research focuses on instruction, assessment, and technologies to support learning in the STEM disciplines. Wolf is passionate about STEM education and engagement both in and out of the classroom. She has worked at The Griffith Observatory in Los Angeles, interned with Discovery Communications, and served as a volunteer outreach scientist with The Franklin Institute in Philadelphia. She was one of the co-founders of the education and outreach committee for The Dark Energy Survey, where she managed, facilitated, and designed programming for an international collaboration of 400+ scientists. Wolf also has extensive teaching experience. She has taught undergraduate physics laboratories and designed curricula for and instructed active-learning undergraduate physics courses.

                              Wolf earned her PhD in Astrophysics from the University of Pennsylvania, where she was an NSF Graduate Research Fellow. While her doctoral work focused on observational cosmology, she also studied how scientists engage with the public through various online platforms. Wolf holds a B.S. in Astrophysics from the University of California, Los Angeles.
                          </p>
                      </div>
                      <div className="6u 12u(medium)">
                          <p><span className="image right"><img src={c2stemwhite} alt="" /></span><strong>Kristen Pilner Blair, Stanford University: </strong> Kristen Blair is a senior research scholar at Stanford University’s Graduate School of Education. She is co-author of the book, the ABCs of How We Learn: 26 Scientifically Proven Approaches, How They Work, and When to Use Them. Her work focuses on improving STEM instruction and assessment in both formal and informal settings. Dr. Blair has collaborated with cross-institutional partners to develop new learning technologies and instructional materials, including computer environments to support science learning by having students take on the role of teachers, an environment that integrates computational thinking and physics through modeling, and a tablet based mathematics game. She also examines fundamental learning questions, such as how students learn from both positive and negative feedback, and how the features of examples provided to students influence their effectiveness for learning.  On the assumption that an important goal of education is to prepare students to continue learning once they leave the classroom, Dr. Blair also works to develop new models of assessment that look not only at the knowledge students develop as a result of a given learning experience, but also how well the experience has prepared them to continue learning in the domain. Dr. Blair holds a PhD in Learning Sciences and Technology Design and an undergraduate degree in Mathematical and Computational Science, both from Stanford University.</p>
                          <p><span className="image left"><img src={c2stemwhite} alt="" /></span><strong>Brian Broll, Vanderbilt University:</strong> Brian Broll is a Research Scientist with the Institute for Software Integrated Systems at Vanderbilt. Brian completed his Ph.D. and M.Sc. in Computer Science from Vanderbilt University. He holds a B.Sc. from Buena Vista University, majoring in mathematics education. His research interests include model integrated computing and computer science education.</p>

                          {/*<p><span className="image right"><img src={pic10} alt="" /></span><strong>Shannon Campe, EDC: </strong> Lorem ipsum dolor sit accumsan interdum nisi, quis tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget tempus vestibulum ante ipsum primis in faucibus magna blandit adipiscing eu felis iaculis.</p>
                                <p><span className="image right"><img src={pic10} alt="" /></span><strong>Nicole Hutchins, Vanderbilt University:</strong>Lorem ipsum dolor sit accumsan interdum nisi, quis tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget tempus vestibulum ante ipsum primis in faucibus magna blandit adipiscing eu felis iaculis.</p>
                                <p><span className="image right"><img src={pic10} alt="" /></span><strong>Naveed Mohammed, Vanderbilt University:</strong>Lorem ipsum dolor sit accumsan interdum nisi, quis tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget. tempus euismod. Vestibulum ante ipsum primis sagittis eget. tempus euismod. Vestibulum ante ipsum primis in faucibus vestibulum. Blandit adipiscing eu felis iaculis volutpat ac adipiscing accumsan eu faucibus. Integer ac pellentesque praesent tincidunt felis sagittis eget tempus vestibulum ante ipsum primis in faucibus magna blandit adipiscing eu felis iaculis.</p>
                           */} </div>
                  </div>


              </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
